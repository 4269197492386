import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

const shake = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(8deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled component for the main layout
const StyledApp = styled.main`
  position: relative;
  background-color: black;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: transform 0.5s ease; /* Smooth transition for rotation */
  box-shadow: 10px 10px 8px rgba(0, 0, 0, 1); /* horizontal offset, vertical offset, blur radius, spread radius, color */

  /* Rotate the entire app when speaking */
  ${({ isSpeaking }) =>
    isSpeaking &&
    css`
      animation: ${rotate} 1s linear infinite; /* Continuous rotation */
    `}

  header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .header-text {
      text-align: center;
      color: #ff0000;

      h2 {
        font-size: 56px;

        @media (max-width: 768px) {
          font-size: 38px;
        }

        @media (max-width: 500px) {
          font-size: 30px;
        }
      }

      h1 {
        font-size: 72px;

        @media (max-width: 768px) {
          font-size: 46px;
        }

        @media (max-width: 500px) {
          font-size: 34px;
        }
      }
    }
  }

  .home-body {
    color: #0000ff;
    h4 {
      span {
      }
    }
  }

  .home-tokenomics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: #00ff00;

    @media (max-width: 768px) {
  font-size:14px;
        }

  }
`;

const JoeImage = styled.img`
  width: 300px;
  height: auto;
  border-radius: 10px;
  ${(props) =>
    props.isSpeaking &&
    css`
      animation: ${shake} 0.2s infinite;
    `}



    @media (max-width: 768px) {
      margin-top:25px;
      width: 200px;
        }
`;

const CustomCursorContainer = styled.div`
  height: 100vh; /* Full height to see the effect */
  cursor: url('/assets/images/note-sm.png'), auto; /* Use your image as cursor */

  /* When clicked, rotate and enlarge the cursor */
  ${({ isClicked }) =>
    isClicked &&
    css`
      cursor: url('/assets/images/note-lg.png') 20 20, auto; /* Larger cursor */
      animation: ${rotate} 0.5s infinite; /* Rotate the cursor */
    `}
`;

function App() {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseDown = () => {
    setIsClicked(true);
  };

  const handleMouseUp = () => {
    setIsClicked(false);
  };

  // Function to handle image click and play random audio
  const handleImageClick = () => {
    if (!isSpeaking) {
      const randomAudio = `/assets/audio/${
        Math.floor(Math.random() * 9) + 1
      }.mp3`;
      const audio = new Audio(randomAudio);

      setIsSpeaking(true); // Set speaking state to true
      audio.play();

      // Once audio ends, reset the speaking state
      audio.onended = () => {
        setIsSpeaking(false);
      };
    }
  };

  return (
    <CustomCursorContainer
      isClicked={isClicked}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <StyledApp isSpeaking={isSpeaking}>
        <header>
          <div className="header-text">
            <h2>Click on me &</h2>
            <h1>LemmeTellYaSomFing</h1>
          </div>
          <JoeImage
            src="/assets/images/joe-face-normal.png"
            alt="Joe Face"
            onClick={handleImageClick}
            isSpeaking={isSpeaking}
          />
        </header>

        <div className="home-body">
          <h4>
            CA: <span>8ocTtDqS2ssWpn7NJQkmcB4XZUZ3fMpPhn9Mt4xrpump</span>
          </h4>
        </div>

        <div className="home-tokenomics">
          <h3>TOKENOMICS:</h3>
          <ul>
            <li>
              <strong>5%</strong>: For the developers’ fun—hookers and a little
              blow, you know how it is!
            </li>

            <li>
              <strong>10%</strong>: A little stash for Joey Diaz—let’s keep that
              wallet fat, baby!
            </li>

            <li>
              <strong>5%</strong>: Gotta spread the word, so we’re throwin’ some
              dough at marketing!
            </li>
          </ul>
        </div>
      </StyledApp>
    </CustomCursorContainer>
  );
}

export default App;
